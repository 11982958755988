@import '../../../../styles/customMediaQueries.css';

.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 0;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }

  @media (--viewportMedium) {
    padding: 0 12px 0 12px;
  }
}

.arrowIcon {
  margin-left: 6px;
  stroke-width: 4px;
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 48px;
  padding-top: 20px;
}

.icon {
  width: 32px;
  height: 18px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.currencyOptionWrapper:last-child {
  margin-bottom: 12px;
}

.currencyOption {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  min-width: 100px;
  width: 100%;
  padding: 4px 12px;

  &:hover {
    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }
}

.currencyLabel {
  margin-left: 8px;
}

.currencyIcon {
  padding: 6px 8px 8px 8px;
  border: 1.5px solid var(--colorGrey100);
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.disabledDropdown {
  pointer-events: none;
  cursor: wait;
  opacity: 0.7;
}

.isOpenIcon {
  animation: rotate-backward 0.25s ease-out forwards;
}

@keyframes rotate-backward {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
